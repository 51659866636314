<template>
  <div class="m-cafe-card">
    <div
      class="m-cafe-card__img-link"
      @click.prevent="selectItem"
    >
      <slot name="image">
        <SfImage
          class="m-cafe-card__img"
          :src="product.sku | productImagePath(234, 234)"
          :alt="product.title"
          :width="imageSize.width"
          :height="imageSize.desktop"
          lazy
        />
      </slot>
    </div>
    <div class="m-cafe-card__desc">
      <span class="m-cafe-card__title" @click.prevent="selectItem">
        {{ truncateTitle(product.title, '...') }}
      </span>
      <div class="m-cafe-card__quantity">
        {{ product.quantityText }}
        <SfPrice
          v-if="product.price.regular"
          class="sf-product-card__price"
          :regular="product.price.regular"
          :special="product.price.special"
        />
      </div>
      <div class="m-cafe-card__action">
        <NoSSR>
          <MProductAddToCart
            :product="product"
            :stock="product.stock"
            is-icon-button
            :is-quantity-available="true"
            class="m-cafe-card__basket"
          />
        </NoSSR>
      </div>
    </div>
  </div>
</template>
<script>
import {
  SfImage,
  SfPrice
} from '@storefront-ui/vue';
import GoogleTagManager from 'theme/mixins/gtm';
import DeviceType from 'theme/mixins/DeviceType';
import isPlainObject from 'lodash/isPlainObject';
import NoSSR from 'vue-no-ssr';

export default {
  name: 'MCafeCard',
  components: {
    SfImage,
    SfPrice,
    MProductAddToCart: () => process.browser ? import('theme/components/molecules/m-product-add-to-cart') : null,
    NoSSR
  },
  mixins: [
    GoogleTagManager,
    DeviceType
  ],
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    image: {
      type: [Array, Object, String],
      default: ''
    },
    esUrlParam: {
      type: String,
      default: ''
    }
  },
  computed: {
    imageSize () {
      return {
        width: this.isMobile ? 80 : 114,
        height: this.isMobile ? 80 : 114
      }
    }
  },
  methods: {
    selectItem () {
      this.gtmSelectItemHandler(
        this.product,
        'select_item',
        {
          item_list_id: 'home_varus_cafe',
          item_list_name: this.$t('VARUS CAFE')
        }
      )

      this.$router.push(this.localizedRoute(this.convertProductLink(this.product.link)))
    },
    truncateTitle (text, suffix) {
      const textLength = this.isMobile ? 50 : 33;
      return text.textLength > textLength ? text.substring(0, textLength) + suffix : text;
    },
    convertProductLink (url) {
      const parentSku = url.params?.parentSku;
      const slug = url.params?.slug;

      return isPlainObject(url) ? `p/${parentSku}/${slug}${this.esUrlParam}` : `${url}${this.esUrlParam}`
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-cafe-card {
  display: flex;
  margin: 0;
  padding: var(--spacer-16);
  background: var(--white);
  height: 146px;

  @media (min-width: $tablet-min) {
    height: 155px;
  }

  @media (min-width: $desktop-min) {
    padding: var(--spacer-16) var(--spacer-18) var(--spacer-15);
  }

  & + & {
    margin-top: var(--spacer-10);
  }

  @include for-desktop {
    ::v-deep .sf-add-to-cart__button {
      opacity: 0;
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      transform: translateY(20%);
    }

    &:hover {
      ::v-deep .sf-add-to-cart__button {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &__img-link {
    display: flex;
    width: 80px;
    cursor: pointer;

    @media (min-width: $tablet-min) {
      width: 114px;
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
    width: calc(100% - 80px);
    padding-left: 1.125rem;
    justify-content: space-between;

    @media (min-width: $tablet-min) {
      width: calc(100% - 100px);
    }

    @media (min-width: $desktop-min) {
      width: calc(100% - 114px);
    }

    .sf-link {
      display: flex;
    }
  }

  &__title {
    font-size: var(--font-size-15);
    margin: 0 0 var(--spacer-10) 0;
    cursor: pointer;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (min-width: $tablet-min) {
      font-size: var(--font-size-18);
    }
  }

  &__quantity {
    font-size: var(--font-size-12);
    color: var(--dark-gray);
    margin-bottom: var(--spacer-8);
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    flex-wrap: nowrap;

    ::v-deep {
      .sf-product-card__price {
        margin: 0;
        flex-wrap: wrap;
        display: flex;
        flex: 0 0;

        span {
          line-height: var(--font-size-22);
        }

        .sf-price__old {
          font-weight: 400;
          font-size: var(--font-size-12);
          line-height: var(--font-size-14);
          text-decoration-line: line-through;
          color: var(--dark-gray);

          @media (min-width: $tablet-min) {
            font-size: var(--font-size-14);
            line-height: var(--font-size-17);
          }
        }

        .sf-price__special {
          font-weight: var(--font-medium);
          font-size: var(--font-size-18);
          line-height: var(--font-size-22);
          color: var(--orange);
        }
      }
    }
  }

  &__action {
    display: block;

    @media (min-width: $tablet-min) {
      display: flex;
    }

    ::v-deep {
      .sf-quantity-selector__button {
        width: var(--spacer-40);
        height: var(--spacer-40);
      }

      .sf-quantity-selector__input {
        height: 2.375rem;
        width: 64px;
      }
    }
  }

  &__basket {
    margin: 0;
  }

  ::v-deep .a-add-to-cart {
    width: var(--spacer-58);
    height: 40px;
  }

  ::v-deep .sf-add-to-cart {
    font-size: 0;
  }
}
</style>
