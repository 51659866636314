<template>
  <SfOCarousel
    class="m-cafe-carousel"
    :settings="sliderSettings"
  >
    <template #prev="{go}">
      <SfButton
        v-show="sliderPerPage > 1"
        class="sf-arrow sf-arrow--rounded sf-arrow--white sf-arrow-prev"
        aria-label="previous"
        @click="goPrev(); go('prev')"
        :disabled="sliderData.disablePrev"
        :class="prevButtonClass"
      />
    </template>
    <SfCarouselItem
      v-for="(cafeProduct, i) in cafeProducts"
      :key="i"
    >
      <template v-if="!isMobile">
        <MCafeCard
          v-for="product in cafeProduct"
          :product="product"
          :key="product.id"
          :image="product.image || product.src"
          :es-url-param="esUrlParam"
        />
      </template>
      <template v-else>
        <MCafeCard
          :product="cafeProduct"
          :image="cafeProduct.image || cafeProduct.src"
          :es-url-param="esUrlParam"
        />
      </template>
    </SfCarouselItem>
    <template #next="{go}">
      <SfButton
        v-show="sliderPerPage > 1"
        class="sf-arrow sf-arrow--rounded sf-arrow--white sf-arrow-next"
        aria-label="next"
        @click="goNext(); go('next')"
        :disabled="sliderData.disableNext"
        :class="nextButtonClass"
      />
    </template>
  </SfOCarousel>
</template>
<script>

import { BREAKPOINT_SM } from 'theme/helpers/breakpoints';
import { SfButton } from '@storefront-ui/vue';
import SfOCarousel from '../storefront-override/SfOCarousel'
import { prepareCategoryProduct } from 'theme/helpers';
import MCafeCard from './m-cafe-card';
import DeviceType from 'theme/mixins/DeviceType';

export default {
  name: 'MCafeCarousel',
  mixins: [DeviceType],
  components: {
    SfOCarousel,
    SfButton,
    MCafeCard
  },
  props: {
    products: {
      type: Array,
      default: () => []
    },
    esUrlParam: {
      type: String,
      default: ''
    },
    sliderSettings: {
      type: Object,
      default: () => {
        return {
          type: 'slider',
          animationDuration: 400,
          animationTimingFunc: 'ease-in-out',
          perView: 2,
          gap: 10,
          dragThreshold: false,
          swipeThreshold: false,
          touchAngle: 80,
          breakpoints: {
            [BREAKPOINT_SM - 1]: {
              dragThreshold: false,
              swipeThreshold: false,
              perView: 1,
              peek: {
                before: 0,
                after: 50
              }
            }
          }
        }
      }
    }
  },
  data () {
    return {
      sliderData: {
        currentPage: 1,
        disablePrev: true,
        disableNext: false
      }
    }
  },
  computed: {
    cafeProducts () {
      const prepareProducts = this.products.map(prepareCategoryProduct)
      if (this.products.length && !this.isMobile) {
        return prepareProducts.reduce((newArr, product, i) => {
          if ((i & 1) === 0) newArr.push([]);
          newArr[newArr.length - 1].push(product);
          return newArr;
        }, [])
      } else if (this.products.length && this.isMobile) {
        return prepareProducts
      }
      return []
    },
    sliderPerPage () {
      return Math.ceil(this.cafeProducts.length / this.sliderSettings.perView)
    },
    prevButtonClass () {
      return {
        'sf-arrow--active': !this.sliderData.disablePrev
      }
    },
    nextButtonClass () {
      return {
        'sf-arrow--active': !this.sliderData.disableNext
      }
    }
  },
  methods: {
    goNext () {
      this.sliderData.currentPage = (this.sliderData.currentPage + 1)
      this.sliderData.disableNext = (this.sliderPerPage === this.sliderData.currentPage)
      this.sliderData.disablePrev = false
    },
    goPrev () {
      this.sliderData.currentPage = (this.sliderData.currentPage - 1)
      this.sliderData.disablePrev = (this.sliderData.currentPage === 1)
      this.sliderData.disableNext = false
    }
  },
  mounted () {
    if (this.sliderPerPage === 1) {
      this.sliderData.disableNext = true
      this.sliderData.disablePrev = true
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/breakpoints";

.m-cafe-carousel {
  ::v-deep {
    .sf-carousel__wrapper {
      margin: 0;
      max-width: 100%;
      width: 100%;
    }

    .sf-carousel__controls {
      top: -75px;
      left: auto;
      right: 0;
      position: absolute;
      width: auto;
    }

    .glide__slides {
      @include for-mobile {
        padding-left: 0;
        padding-right: var(--spacer-5);
        touch-action: pan-x;
        margin-left: var(--spacer-10);
      }
    }
    .glide__track {
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }

      @media (min-width: $desktop-min) {
        overflow: unset;
      }
    }
  }
}
</style>
